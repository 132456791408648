// GalleryPage.js
import React from 'react';
import '../pages/Gallery.css';

// Import your actual images
import image2023 from '../assests/The Hills Venue.jpg';
import image2021a from '../assests/DSC01758.JPG';
import image2021b from '../assests/SAJ_7370.JPG';
import image2020a from '../assests/Rectangle 40.png';
import image2020b from '../assests/KEN02490.jpg';
import image2020c from '../assests/camp.png';
import image2020d from '../assests/couple.JPG';

const galleryItems = [
  { src: image2023, style: {gridColumn: "1 / span 4", gridRow: "1 / span 1", borderRadius: "40px" } },
  { src: image2021a, style: { gridColumn: "1 / span 1", gridRow: "2 / span 1", borderRadius: "40px" } },
  { src: image2021b, style: { gridColumn: "2 / span 3", gridRow: "2 / span 1", borderRadius: "40px" } },
  { src: image2020c, style: {  gridColumn: "1 / span 1", gridRow: "3 / span 1", borderRadius: "40px" } },
  { src: image2020b, style: {  gridColumn: "2 / span 1", gridRow: "3 / span 1", borderRadius: "40px" } },
  { src: image2020a, style: {  gridColumn: "3 / span 1", gridRow: "3 / span 1", borderRadius: "40px" } },
  { src: image2020d, style: {  gridColumn: "4 / span 1", gridRow: "3 / span 1", borderRadius: "40px" } },
];

const GalleryPage = () => {
  return (
    <div className="gallery">
      <div className="gallery-title-container">
        <div className="vector-top"></div>
        <div className="vector-left"></div>
        <h2 className="gallery-title">Legacy of The Hills Festival</h2>
        <div className="vector-right"></div>
        <div className="vector-bottom"></div>
      </div>
      <div className="gallery-container">
        {galleryItems.map((item, index) => (
          <div
            key={index}
            className="gallery-item"
            style={item.style}
          >
            <img src={item.src} alt={`Gallery image ${index + 1}`} className="gallery-image" />
          </div>
        ))}
      </div>
    </div>
  );
};
export default GalleryPage;
