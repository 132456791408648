// Lineup.jsx
import React from 'react';
import './Lineup.css';
import leftDecoration from '../assests/Orchid n Fungi Pillar (3).png';
import rightDecoration from '../assests/Orchid n Fungi Pillar (4).png';
import artistImage from '../assests/Untitled design (17).png';
import lineupIcon from '../assests/Layer_1.png';
import vectorIcon from '../assests/Vector.png';

const Lineup = () => {
  return (
    <div className="lineup-section">
        {/* Access Lineup Header */}
      <div className="lineup-header">
        <img src={lineupIcon} alt="Lineup Icon" className="lineup-icon" />
        <i className="fas fa-flower-lineup-icon"></i> ARTISTS LINEUP
      </div>

      {/* Decorative Flowers */}
      <img src={leftDecoration} alt="Left Decoration" className="flower-decoration left" />
      <img src={rightDecoration} alt="Right Decoration" className="flower-decoration right" />

      
      {/* Artist Lineup List */}
      <article className="artists-lineup">
        <p>
          <span className="stage">Hills Main Stage</span>
          <br />
          I BUILT THE SKY (AUS) / TIANA KHASI (AUS) / YUNG SAMMY (NG/IND) / MAYA LYNGDOH /
          YASHRAJ / ISHAN (AUS) / Murder in Space / The Dixie Boys / MOOKHURI / Kim the
          Beloved /
        </p>
        <p>
          <span className="stage">Electric Hills Stage</span>
          <br />
          LIS VEGAS (USA) / ROBU (IND/UK) / AIOM / BRIAN / DJ WALTER / DJ MEBAN /
          INSOWMYA / RASA / PENNY PLAN / SEV / CASSIDY GODWIN
        </p>
      </article>
      <div className="center-content">
        {/* Get Tickets Button */}
        <a
          href="https://in.bookmyshow.com/activities/the-hills-festival/ET00416806?webview=true"
          target="_blank"
          rel="noopener noreferrer"
          className="get-tickets-button"
        >
          <div className="button-label">
            GET TICKETS <img src={vectorIcon} alt="Arrow Icon" className="vector-icon" />
          </div>
        </a>

      {/* Artist Image Section */}
      <div className="artist-image">
        <img src={artistImage} alt="Artist" />
      </div>
    </div></div>
      
  );
};

export default Lineup;
